// Основные цветов
$yellow: #ffb800;
$white: #fff;
$red: #a81933;
$green: #1fba9d;

// Настройки проекта
$xy-grid: false;
$global-flexbox: true;
$global-font-size: 16px;
$global-width: 1200px;
$foundation-palette: (
  primary: #1779ba,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: pink,
);
$body-background: #0C1122;
$body-font-family: 'Whitney', sans-serif;
$body-font-color: #000000;
$transition-parametr: 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
$breakpoint-classes: (small medium large);
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1200px,
);
$grid-column-gutter: (
  small: 40px,
  medium: 40px,
  large: 30px,
);

