@import './src/Variables';
@import '~foundation-sites/scss/util/util';

.containerLink {
  text-decoration: none;
  display: inline-block;

  &:focus {
    .container {
      background: #FF6A13;
      outline: 2px solid #2F9BFF;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .container {
      background: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .text {
      color: #63657E;
    }

    .icon {
      opacity: 0.2;
    }
  }
}

.container {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0 20px;
  border-radius: 20px;
  display: inline-block;
  transition: background-color $transition-parametr;

  &:hover {
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  &:active {
    background: #FF6A13;
    border: 1px solid transparent;
  }
}

.text {
  font-size: 14px;
  line-height: 130%;
  color: #fff;
  display: inline-block;
  padding: 4px 0;
  @include breakpoint(medium) {
    padding: 6px 0;
  }
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  vertical-align: middle;
  position: relative;

  svg {
    position: absolute;

  }
}
