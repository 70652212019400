@import './src/Variables';
@import '~foundation-sites/scss/util/util';

// Modal
:global(.modal-active) {
  @include breakpoint(medium down) {
    height: 100%;
    overflow-y: hidden;
  }
}
.modal {
  z-index: 6;
  background-size: cover;
  overflow: hidden;
  width: 100%;
  height: 432px;
  filter: drop-shadow(0px 16px 80px rgba(0, 0, 0, 0.56));
  background: url('./assets/bg-modal-small.png') #213272 no-repeat;
  @include breakpoint(medium down) {}
  @include breakpoint(small only) {
    border-radius: 24px 24px 0 0;
    bottom: 0;
    position: fixed;
  }
  @include breakpoint(medium) {
    background: url('./assets/bg-modal-medium.jpg') #213272 no-repeat;
    width: 604px;
    height: 440px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 24px;
    @include vertical-center;
  }
  @include breakpoint(medium only) {

  }
  @include breakpoint(large) {
    background: url('./assets/bg-modal.png') #213272 no-repeat;
    background-size: contain;
    width: 965px;
    height: 523px;
  }
}
.modalInner {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 24px;
  @include breakpoint(medium) {
    padding-left: 32px;
  }
  @include breakpoint(large) {
    padding-left: 56px;
  }
  @include breakpoint(medium) {
    &:after {
      content: "";
      width: 440px;
      height: 316px;
      background: url('./assets/bg-medium-peoples.png') right no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  @include breakpoint(large) {
    &:after {
      background: url('./assets/bg-peoples.png') no-repeat;
      background-size: cover;
      width: 639px;
      height: 459px;
    }
  }
  & > * {
    position: relative;
    z-index: 6;
  }
}
.modalHeadline {
  font-family: 'PFDinTextCompPro', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  margin-top: 48px;
  font-size: 48px;
  line-height: 110%;
  margin-bottom: 24px;
  @include breakpoint(large) {
    font-size: 56px;
    line-height: 110%;
    margin-top: 80px;
    margin-bottom: 32px;
  }
  span {
    color: #FF6A13;
    display: block;
    font-size: 72px;
    line-height: 110%;
    @include breakpoint(large) {
      font-size: 80px;
      line-height: 110%;
    }
  }
}
.modalSubContent {
  color: #fff;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 30px;
  @include breakpoint(large) {
    margin-bottom: 40px;
  }
  span {
    display: block;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
  }
}
.modalContent {
  width: 350px;
  color: #fff;
  font-weight: 300;
  font-size: 24px;
}

.modalButtonContainer {
  position: absolute;
  z-index: 6;
  right: 8px;
  top: 8px;
  @include breakpoint(large) {
    right: 32px;
    top: 24px;
  }
}

.modalButton {
  display: inline-block;
  width: 56px;
  height: 56px;
  cursor: pointer;
  &:hover {
    svg {
      fill: #FF6A13;
    }
  }
  svg {
    width: 28px;
    height: 28px;
    left: 0;
    right: 0;
    margin: auto;
    @include vertical-center;
  }
}
