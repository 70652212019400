@import './src/Variables';
@import '~foundation-sites/scss/util/util';

:global(.page.magic) {
  @include breakpoint(large) {
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 20px auto 0;
    left: 0;
    right: 0;
  }
}

.headline {
  font-family: 'PFDinTextCompPro', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  padding: 0;
  font-size: 72px;
  line-height: 110%;
  margin: 0 0 40px;
  @include breakpoint(medium) {
    font-size: 104px;
    margin-bottom: 30px;
  }
  @include breakpoint(large) {
    margin-bottom: 60px;
  }
}

.buttonLink {
}

.featuresHeadline {
  font-family: 'PFDinTextCompPro', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  color: #FF6A13;
  margin: 0;
  padding: 0;
  @include breakpoint(medium) {
    font-size: 40px;
  }
  @include breakpoint(large) {
    margin-bottom: 80px;
  }
}

.featuresContainer {
  @include breakpoint(small only) {
    padding-top: 40px;
    margin-bottom: 20px;
  }
  @include breakpoint(medium only) {
    padding-top: 50px;
    margin-bottom: 80px;
  }
}

.features {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.feature {
  width: 100%;
  margin-bottom: 32px;
  list-style-type: none;
  @include breakpoint(medium) {
    flex: 50%;
    margin-bottom: 60px;
  }
}

.featureInner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: normal;
  align-content: center;
}

.feautureIcon {
  display: inline-block;
  background-color: red;
  position: relative;

  svg {
    vertical-align: middle;
    width: 32px;
    height: 32px;
    @include vertical-center
  }
}

.featureText {
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  line-height: 120%;
  color: #fff;
  padding-left: 56px;
  @include breakpoint(medium) {
    font-size: 24px;
  }
}
