@mixin backgroud-gray() {
    background-color: #f9f9f9;
}
@mixin box-shadow-default($opacity: 0.05, $color: $black) {
    box-shadow: 0 rem-calc(10) rem-calc(15) rgba($color, $opacity);
}
@mixin vertical-align() {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}
@mixin horizontal-align() {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
@mixin ir($width: 0) {
    border: $width;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}

.bold     { font-weight: bold; }
.regular  { font-weight: normal; }
.italic   { font-style: italic; }

.ell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.break-word { word-wrap: break-word; }
.no-wrap    { white-space: nowrap; }

.text-left    { text-align: left; }
.text-right   { text-align: right; }
.text-center  { text-align: center; }
.small-text-center {
    @include breakpoint(small only) {
        text-align: center;
    }
}
.medium-text-center {
    @include breakpoint(small only) {
        text-align: center;
    }
}
.text-just    { text-align: justify; }

.align-top    { vertical-align: top !important; }
.align-bottom { vertical-align: bottom !important; }
.align-middle { vertical-align: middle !important; }
.align-baseline { vertical-align: baseline !important; }

.left  { float: left; }
.right { float: right; }
.clearfix {
    @include clearfix;
}
.flow-hidden {
    overflow: hidden;
}

.fit         { max-width: 100%; }
.half-width  { width: 50% }
.full-width  { width: 100%; }
.full-height { height: 100%; }

.fixed    { position: fixed; }
.relative { position: relative; }
.absolute { position: absolute; }
.static   { position: static; }

.zindex-1 { z-index: 1; }
.zindex-2 { z-index: 2; }
.zindex-3 { z-index: 3; }

.height-140 {
    height: rem-calc(140);
}

.block {display: block}
.inline-block {display: inline-block}
.inline {display: inline}

.font12 {
    font-size: rem-calc(12) !important;
}

.font14 {
    font-size: rem-calc(14) !important;
}

.font20 {
    font-size: rem-calc(20) !important;
}

.mobile-centered {
    @include breakpoint(medium down) {
        text-align: center;
    }
}

.image-centered {
    text-align: center;
    max-width: inherit;
}

.mobile-image-centered {
    @include breakpoint(medium down) {
        max-width: inherit;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        left: 0;
        right: 0;
    }
}

.box-shadow-default {
    @include box-shadow-default;
}

.box-shadow-50 {
    box-shadow: 0 rem-calc(10) rem-calc(15) rgba($black, 0.5);
}


// Float
.float-left  {
    float: left;
}
.small-float-left-only {
    @include breakpoint(small only) {
        float: left;
    }
}
.medium-float-left-only {
    @include breakpoint(medium only) {
        float: left;
    }
}
.large-float-left {
    @include breakpoint(large) {
        float: left;
    }
}
.float-right {
    float: right;
}
.small-float-right-only {
    @include breakpoint(small only) {
        float: right;
    }
}
.medium-float-right-only {
    @include breakpoint(medium only) {
        float: right;
    }
}
.large-float-right {
    @include breakpoint(large) {
        float: right;
    }
}

// Выравнивание текста
.text-center {
    text-align: center;
}
.small-text-center-only {
    @include breakpoint(small only) {
        text-align: center;
    }
}
.medium-text-center-only {
    @include breakpoint(medium only) {
        text-align: center;
    }
}
.large-text-center {
    @include breakpoint(large) {
        text-align: center;
    }
}
.text-left {
    text-align: left;
}
.small-text-left-only {
    @include breakpoint(small only) {
        text-align: left;
    }
}
.medium-text-left-only {
    @include breakpoint(medium only) {
        text-align: left;
    }
}
.large-text-left {
    @include breakpoint(large) {
        text-align: left;
    }
}
.text-right {
    text-align: right;
}
.small-text-right-only {
    @include breakpoint(small only) {
        text-align: right;
    }
}
.medium-text-right-only {
    @include breakpoint(medium only) {
        text-align: right;
    }
}
.large-text-right {
    @include breakpoint(large) {
        text-align: right;
    }
}
