@charset "utf-8";
@import-normalize;
@import '~foundation-sites/scss/foundation';
@import 'Variables';
@import "helpers/Margin";
@import "helpers/Padding";
@import "helpers/Position";
@import "helpers/FormLayout";

html {
  height: 100%;
  @include breakpoint(large) {
    overflow: hidden;
  }
}

body {
  margin: 0;
  padding: 0;
  @include breakpoint(large) {
    width: 100%;
    height: 100%;
  }
}

// Подключаемые модули
@include foundation-global-styles;
@include foundation-visibility-classes;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-callout;
@include foundation-prototype-position;
@include foundation-prototype-text-utilities;
