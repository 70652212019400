@import './src/Variables';
@import '~foundation-sites/scss/util/util';

.navigationContainer {
  padding: 16px 0 10px;
  .team &,
  .order & {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.navigation {
}

.logo {
  display: inline-block;
  overflow: hidden;
  width: 178px;
  height: 28px;
  min-height: 0;
  @include breakpoint(medium) {
    width: 204px;
    height: 32px;
  }

  svg {
    width: 100%;
    height: 0;
    min-height: 100%;
  }
}

.headerButton {
  float: right;
  display: none;

  .onboard &,
  .magic & {
    display: block;
  }
}

.generalPage {
  @include breakpoint(medium) {
    height: 100vh;
    width: 100vw;
  }
  @include breakpoint(large) {
    height: 100vh;
    width: 100vw;
  }

  &.showModal {
    &:before {
      background: rgba(31, 31, 51, 0.41);
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      left: 0;
      top: 0;
      z-index: 5;
      @include breakpoint(small only) {
        position: fixed;
      }
    }
  }
}

.onboard {
  background: url('./assets/bg-mobile.png') no-repeat;
  @include breakpoint(medium) {
    background: url('./assets/bg-tablet.png') no-repeat;
  }
  @include breakpoint(large) {
    background: url('./assets/bg-large.png') center no-repeat;
    background-size: cover;
  }
}

.team,
.order {
  background: url('./assets/bg-mobile-other.png') no-repeat #0B1B4C;
  @include breakpoint(medium) {
    background: url('./assets/bg-tablet-other.png') no-repeat;
  }
  @include breakpoint(medium only) {
    height: auto;
  }
  @include breakpoint(large) {
    background: url('./assets/bg-large-other.png') center no-repeat;
    background-size: cover;
  }
}

.finish,
.magic {
  background-size: cover !important;
  height: 100vh;
  background: url('./assets/bg-small-magic.png') no-repeat;
  @include breakpoint(medium) {
    background: url('./assets/bg-medium-magic.png') left top no-repeat;
    background-size: cover;
  }
  @include breakpoint(medium only) {
    height: auto;
  }
  @include breakpoint(large) {
    background: url('./assets/bg-large-magic.png') center no-repeat;
  }
}
