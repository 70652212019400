@import './src/Variables';
@import '~foundation-sites/scss/util/util';

// Элементы формы
.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: normal;
  @include breakpoint(small only) {
    padding: 0 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;
  }
  @include breakpoint(medium only) {
    padding: 0 20px;
  }
  @include breakpoint(medium) {
    margin-bottom: 8px;
  }
  &.one-column {
    .form-column {
      width: 100%;
      @include breakpoint(medium) {
        width: 100%;
      }
    }
  }
  &.two-columns {
    .form-column {
      width: 100%;
      @include breakpoint(medium) {
        width: 50%;
      }
    }
  }
  &.three-column {
    .form-column {
      width: 100%;
      @include breakpoint(medium) {
        width: 33%;
      }
    }
  }
}
.form-column {
  margin-right: 8px;
  @include breakpoint(small only) {
    margin-bottom: 8px;
  }
  &:last-child {
    margin-right: 0;
  }
}
