/*------------------------------------*\
  Внешние отступы
/*------------------------------------*/

$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 75, 80, 85, 90 ,100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: rem-calc($space) !important;
    }
  }
}

@each $space in $spaceamounts {
    @each $side in $sides {
        .small-m-#{str-slice($side, 0, 1)}-#{$space} {
            @include breakpoint(small only) {
                margin-#{$side}: rem-calc($space) !important;
            }
        }
    }
}

@each $space in $spaceamounts {
    @each $side in $sides {
        .medium-m-#{str-slice($side, 0, 1)}-#{$space} {
            @include breakpoint(medium only) {
                margin-#{$side}: rem-calc($space) !important;
            }
        }
    }
}


.no-margin {
    margin: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}
.small-no-margin-top {
    @include breakpoint(small only) {
        margin-top: 0 !important;
    }
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}

.small-no-margin-left {
    @include breakpoint(small only) {
        margin-left: 0 !important;
    }
}
.medium-no-margin-left {
    @include breakpoint(small only) {
        margin-left: 0 !important;
    }
}

.small-no-margin-right {
    @include breakpoint(small only) {
        margin-right: 0 !important;
    }
}
.medium-no-margin-right {
    @include breakpoint(small only) {
        margin-right: 0 !important;
    }
}
