@import './src/Variables';
@import '~foundation-sites/scss/util/util';

.linkContainer {

}
.btn {
  padding: 0 32px;
  display: inline-block;
  text-decoration: none;
  background: #FF6A13;
  border: 1px solid #FF6A13;
  transition: background-color $transition-parametr;

  &:hover {
    background: #FF8C21;
    border: 1px solid #FF8C21;
  }

  &:active {
    background: #E75500;
    border: 1px solid #E75500;
  }

  &:focus {
    background: #FF6A13;
    border: 1px solid #FF6A13;
    outline: 2px solid #2F9BFF;
  }

  &.disabled {
    pointer-events: none;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &.expanded {
    width: 100%;
    text-align: center;
  }
}

.btn-normal {
  border-radius: 57px;

  .text {
    font-size: 16px;
    line-height: 120%;
    padding: 12px 0;
  }
}

.btn-large {
  border-radius: 57px;

  .text {
    text-align: center;
    font-size: 18px;
    line-height: 120%;
    padding: 21px 0;
  }
}

.text {
  display: inline-block;
  color: #fff;

  .disabled & {
    color: #63657E;
  }
}

.labelText {
  font-size: 14px;
  font-weight: 300;
  line-height: 130%;
  color: #8E90AE;
  display: inline-block;
  @include breakpoint(medium) {
    margin-left: 22px;
  }
  .expanded & {
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
}
